import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function App() {
  const [serviceWorkerReg, setServiceWorkerReg] = useState<ServiceWorkerRegistration | undefined>(undefined);

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onSuccess: () => {
        console.log("Success!  What sort of success, I wonder?");
      },
      onUpdate: (reg) => {
        console.log("New version, reg ready to use");
        setServiceWorkerReg(reg);
      },
    });
  }, []);

  function updateSoftware() {
    if (serviceWorkerReg) {
      serviceWorkerReg.waiting?.postMessage({ type: "SKIP_WAITING" });
    }
    // window.location.reload();
  }

  return (
    <div className="App">
      <header className="App-header">Testing Service Worker</header>

      <div className="content">
        <p>version 23</p>

        {serviceWorkerReg && <button onClick={updateSoftware}>Install Update</button>}
      </div>
    </div>
  );
}

export default App;
